import { gql } from "@apollo/client/core";

export const PromoCodeQuery = gql`
  query PromoCode($code: String) {
    promoCode(code: $code) {
      code
      codeText
      heroLine
      heroSparkle
      specialOfferText
      isDefault
      heroLink
    }
    defaultPromoCode: promoCode {
      code
      codeText
      heroLine
      heroSparkle
      specialOfferText
      isDefault
      heroLink
    }
    getPromoDiscounts(code: $code) {
      discounts {
        discount_type
        duration_usage_limit
        value
      }
      error
    }
  }
`;

export const PromoDiscountsQuery = gql`
  query PromoDiscounts($code: String) {
    getPromoDiscounts(code: $code) {
      discounts {
        discount_type
        duration_usage_limit
        value
      }
      error
    }
  }
`;
