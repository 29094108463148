"use client";
import NextLink, { LinkProps } from "next/link";
import { usePromoCode } from "@/data/helpers/promoCode/hooks";
import { addDiscountCode } from "@/components/Link/utils";

export default function LinkWithDiscountCode({ href, ...props }: LinkProps) {
  const { code } = usePromoCode();
  const hrefWithCode = code ? addDiscountCode(href, code) : href;

  return <NextLink {...props} href={hrefWithCode} />;
}
