import type {
  PropsWithChildren,
  JSX,
  ComponentType,
  Dispatch,
  SetStateAction,
} from "react";
import { useState, Fragment } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { clsx } from "../utils/clsx";
import { Button } from "./button";
import { Leaf2, Lemon, LemonSlice } from "./icons";

interface BottomBannerProps extends PropsWithChildren {
  verticalAlignment?: "middle" | "bottom";
  isOpen?: boolean;
  setIsOpen: (status: boolean) => void;
  onClose?: () => void;
  className?: string;
}

export function BottomBanner({
  children,
  isOpen = false,
  setIsOpen = () => null,
  onClose = () => null,
  className,
  verticalAlignment = "bottom",
}: BottomBannerProps): JSX.Element | null {
  if (!isOpen) {
    return null;
  }
  return (
    <Transition appear as={Fragment} show={isOpen}>
      <div
        className={clsx(
          { "fixed inset-x-0 bottom-0 z-50 border-t-2 border-t-white": verticalAlignment === "bottom" },
          { "fixed inset-0 flex justify-center items-center bg-black/40 z-50": verticalAlignment === "middle" },
          className,
        )}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className={clsx(
            "bg-cgc-blue relative transform overflow-hidden p-3 align-middle transition-all md:p-[30px]",
            { 
              "w-full": verticalAlignment === "bottom",
              "max-w-screen-sm mx-auto rounded shadow-lg": verticalAlignment === "middle",
            }

            )}>
            <Leaf2 className="absolute -left-4 top-0 h-auto w-[56px] rotate-[15deg] -scale-x-100 opacity-80 md:w-[76px]" />
            <Lemon className="absolute -bottom-[40px] left-[75px] hidden h-auto w-[78px] -scale-x-100 opacity-80 md:block" />
            <LemonSlice className="absolute -right-[25px] bottom-3 h-auto w-[55px] -rotate-[38deg] opacity-70 md:-right-[35px] md:w-[92px]" />
            <Button
              className="absolute right-1 top-1 p-1"
              color="unstyled"
              onClick={() => {
                onClose();
                setIsOpen(false);
              }}
            >
              <XMarkIcon className="h-[19px] w-[19px] text-white md:h-[29px] md:w-[29px]" />
            </Button>
            {children}
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
}

interface UseBottomBannerResult {
  BottomBanner: ComponentType<Omit<BottomBannerProps, "setIsOpen">>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const useBottomBanner = (status = false): UseBottomBannerResult => {
  const [isOpen, setIsOpen] = useState(status);

  return {
    BottomBanner: (props: Omit<BottomBannerProps, "setIsOpen">) => (
      <BottomBanner {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
    ),
    setIsOpen,
  };
};
