export const MARoutes = {
  // Subscriptions
  SUBSCRIPTIONS_LIST: "/members-area/subscriptions",
  PREVIOUS_SUBSCRIPTIONS_LIST: "/members-area/subscriptions/previous",
  ADD_NEW_SUBSCRIPTION: "/members-area/subscriptions/new",
  // Orders
  ORDERS_LIST: "/members-area/orders",
  // Profile
  PROFILE: "/members-area/profile",
  BILLING_ADDRESS: "/members-area/profile/billing-address",
  ACCOUNT_DETAILS: "/members-area/profile/account-details",
  PAYMENT_DETAILS: "/members-area/profile/payment-details",
  ADD_THIRST_CLASS: "/members-area/thirst-class",
  // Refer a Friend
  REFER_A_FRIEND: "/members-area/refer-a-friend",
  // Rewards
  REWARDS: "/members-area/rewards",
  // offers
  OFFERS: "/members-offers",
};

export const MAQuickLinks = [
  { path: "/upgrade", target: MARoutes.SUBSCRIPTIONS_LIST },
  { path: "/receipt", target: MARoutes.ORDERS_LIST },
  { path: "/ginpals", target: MARoutes.REFER_A_FRIEND },
  { path: "/sms-reward", target: `${MARoutes.REWARDS}?reward=sms` },
  { path: "/details", target: MARoutes.PAYMENT_DETAILS },
  { path: "/rewards", target: MARoutes.REWARDS },
  { path: "/contact-details", target: MARoutes.ACCOUNT_DETAILS },
  { path: "/birthday", target: `${MARoutes.REWARDS}?reward=birthday` },
  { path: "/rejoin", target: MARoutes.PREVIOUS_SUBSCRIPTIONS_LIST },
  { path: "/add-rum", target: `${MARoutes.ADD_NEW_SUBSCRIPTION}?spirit=rum` },
  { path: "/go-thirst-class", target: MARoutes.ADD_THIRST_CLASS },
  { path: "/member-offers", target: MARoutes.OFFERS },
];

export const OLD_MA_QUICK_LINK_REDIRECT = "/old-quick-link";
