"use client";
import { useEffect, useState } from "react";
import Script from "next/script";
import { Logo } from "@cgc/ui";

declare global {
  interface Window {
    richSnippetReviewsWidgets: any;
  }
}

const ReviewsWidget: React.FC = () => {
  const [scriptReady, setScriptReady] = useState(false);

  useEffect(() => {
    if (scriptReady) {
      window.richSnippetReviewsWidgets("reviews_container", {
        store: "craft-clubs",
        starsClr: "#F2D840",
        textClr: "#FFFFFF",
        logoClr: "white",
        widgetName: "text-banner",
        css:
          ".TextBanner {margin: 0;}" +
          ".TextBanner .TextBanner__text--customerssay {display: none;}" +
          ".TextBanner span { font-size: 32px; } .ruk_word { font-size: 14px !important}" +
          ".TextBanner .TextBanner__text--verdict {line-height: 7px; text-transform: uppercase;}" +
          ".ruk_count { font-size: 12px !important; } .ruk_rating { font-size: 12px !important;}" +
          ".ruk_rating { font-size: 12px !important;} .TextBanner .TextBanner__stars {font-size: 32px !important; }" +
          ".TextBanner .TextBanner__stars i.ruk-icon-percentage-star-75-01 { font-size: 26px !important; top: -4px;}" +
          ".TextBanner .TextBanner__stars.RatingStars--large i.RatingStars__star {font-size: 18px}" +
          ".TextBanner .ReviewsLogo {display: block;}" +
          ".TextBanner .noBreakWrap {margin-top: 12px}" +
          ".TextBanner .TextBanner__text--outof {line-height: 13px;}",
      });
    }
  }, [scriptReady]);

  return (
    <>
      <Script
        src="https://widget.reviews.co.uk/rich-snippet-reviews-widgets/dist.js"
        onLoad={() => setScriptReady(true)}
      />
      <div className="xs:flex-row mt-7 flex flex-col items-center justify-center md:mt-0 md:flex-col">
        <a
          href="https://www.reviews.co.uk/company-reviews/store/craft-clubs?utm_source=craft-clubs&utm_medium=widget&utm_campaign=text-banner"
          target="_blank"
          rel="nofollow"
          aria-label="More reviews for Craft Gin Club"
        >
          <Logo circled className="mx-2 mb-4 mt-1 h-[60px] w-[60px]" />
        </a>
        <div id="reviews_container" />
      </div>
    </>
  );
};

export default ReviewsWidget;
