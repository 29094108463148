"use client";

import { useSearchParams } from "next/navigation";
import { useState, useEffect, useRef } from "react";

export const useLoyaltyLionCustomer = () => {
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const searchParams = useSearchParams();

  const rewardPopup = useRef(null);

  const showRewardPopup = () => {
    const rewardParam = searchParams.get("reward");

    if (!rewardParam) return;

    if (rewardParam === "sms") {
      rewardPopup.current = document.querySelector(
        ".lion-rule-item--122108 .lion-action-button",
      );
    }

    if (rewardParam === "birthday") {
      rewardPopup.current = document.querySelector(
        ".lion-rule-item--birthday .lion-action-button",
      );
    }

    rewardPopup.current && rewardPopup.current.click();
  };

  useEffect(() => {
    const checkLoyaltyLionCustomer = () => {
      if (
        typeof window !== "undefined" &&
        window.loyaltylion &&
        window.loyaltylion.customer
      ) {
        setCustomer(window.loyaltylion.customer);
        setIsLoading(false);
        clearInterval(intervalId); // Clear interval once the customer object is found
        showRewardPopup();
      }
    };

    let intervalId;
    if (!customer) {
      intervalId = setInterval(checkLoyaltyLionCustomer, 1000); // Check every 1000ms (1 second)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Ensure the interval is cleared when the component unmounts
      }
    };
  }, [customer]);

  return [customer, isLoading];
};

export const useLoyaltyLionProgram = () => {
  const [program, setProgram] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkLoyaltyLionProgram = () => {
      if (
        typeof window !== "undefined" &&
        window.loyaltylion &&
        window.loyaltylion.program
      ) {
        setProgram(window.loyaltylion.program);
        setIsLoading(false);
        clearInterval(intervalId); // Clear interval once the program object is found
      }
    };

    let intervalId;
    if (!program) {
      intervalId = setInterval(checkLoyaltyLionProgram, 1000); // Check every 1000ms (1 second)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Ensure the interval is cleared when the component unmounts
      }
    };
  }, [program]);

  return [program, isLoading];
};

export const useLoyaltyLionCustomerTier = () => {
  const [tier, setTier] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [customer, customerIsLoading] = useLoyaltyLionCustomer();
  const [program, programIsLoading] = useLoyaltyLionProgram();

  useEffect(() => {
    if (!customerIsLoading && !programIsLoading) {
      const loyaltyTierId = customer.loyaltyTierMembership.loyaltyTierId;

      const customerTier = program.loyaltyTiers.find(
        (loyaltyTier) => loyaltyTier.id === loyaltyTierId,
      );

      setTier(customerTier);
      setIsLoading(false);
    }
  }, [customer, program]);

  return [tier, isLoading];
};

export const useLoyaltyLionRefresh = () => {
  // Const state value to be returned to allow for manually triggering the effect
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh) {
      if (
        window &&
        window.loyaltylion &&
        window.loyaltylion.ui &&
        window.loyaltylion.ui.refresh
      ) {
        window.loyaltylion.ui.refresh();
      }

      setRefresh(false);
    }
  }, [refresh]);

  return setRefresh;
};
