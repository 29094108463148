"use client";

import { Paragraph } from "@cgc/ui";
import { ChatBubbleOvalLeftEllipsisIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";

const PopupForm: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      const script = document.createElement("script");
      script.src =
        "https://contact.gorgias.help/api/contact-forms/loader.js?v=3";
      script.defer = true;
      script.setAttribute("data-gorgias-loader-contact-form", "");
      script.setAttribute("data-gorgias-contact-form-uid", "qmj19wev");
      document.getElementById("gorgias-form-container")?.appendChild(script);
    }
  }, [isOpen]);

  return (
    <div>
      <button
        onClick={togglePopup}
        className="bg-cgc-blue fixed right-0 top-1/2 z-10 origin-bottom-right -rotate-90 transform rounded-t-lg px-2 pt-1 text-white shadow-lg"
      >
        <div className="flex items-center gap-1">
          <ChatBubbleOvalLeftEllipsisIcon className="size-4" />
          <Paragraph size="p3">Share feedback</Paragraph>
        </div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative max-h-[90vh] w-96 overflow-scroll rounded-lg bg-white p-6 shadow-lg lg:max-w-screen-sm">
            <div className="space-between flex">
              <button
                onClick={togglePopup}
                className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
              >
                &times;
              </button>
            </div>
            <div>
              <div id="gorgias-form-container"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupForm;
