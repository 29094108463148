"use client";
import { useEffect } from "react";
import { useSearchParams } from "next/navigation";
import { useQuery } from "@apollo/client/react/hooks";
import { add } from "date-fns";

import { PromoCodeQuery } from "@/data/helpers/promoCode/queries";

export const DISCOUNT_CODE_KEY = "discount_code";
const DISCOUNT_TEXT_KEY = "discount_text";
const DISCOUNT_EXPIRY_DATE_KEY = "discount_expiry_date";

export type DiscountCodeParams = {
  discount_code?: string;
};

export default function useDiscountCodeParam() {
  return useSearchParams()?.get(DISCOUNT_CODE_KEY);
}

export function getItem(key: string) {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(key);
  }

  return null;
}

export function setItem(key: string, value: string) {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, value);
  }
}

function removeItem(key: string) {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(key);
  }
}

export function setExpired(now = new Date()) {
  setItem(DISCOUNT_EXPIRY_DATE_KEY, add(now, { days: 3 }).getTime().toString());
}

function checkIsExpired() {
  if (typeof window === "undefined") {
    return;
  }

  if (!getItem(DISCOUNT_CODE_KEY)) {
    return;
  }

  const now = new Date();
  const expired = getItem(DISCOUNT_EXPIRY_DATE_KEY);

  if (!expired) {
    setExpired(now);

    return;
  }

  if (Number(expired) < now.getTime()) {
    removeItem(DISCOUNT_CODE_KEY);
    removeItem(DISCOUNT_TEXT_KEY);
    removeItem(DISCOUNT_EXPIRY_DATE_KEY);
  }

  return;
}

export function usePromoCode({ skip = false }: { skip?: boolean } = {}) {
  const paramDiscountCode = useSearchParams()?.get(DISCOUNT_CODE_KEY);
  const paramDiscountText = useSearchParams()?.get(DISCOUNT_TEXT_KEY);

  checkIsExpired();

  const storedDiscountCode = getItem(DISCOUNT_CODE_KEY);
  const discountCode = paramDiscountCode ?? storedDiscountCode;
  const storedDiscountText = getItem(DISCOUNT_TEXT_KEY);
  const discountText = paramDiscountText ?? storedDiscountText;

  // fetch promoCampaign by code
  // fetch defaultPromoCampaign
  // fetch discounts by code
  const { data } = useQuery(PromoCodeQuery, {
    variables: { code: discountCode as string },
    skip: !discountCode || skip,
  });

  const fetchedCode: string =
    data?.getPromoDiscounts?.discounts?.length > 0
      ? discountCode
      : data?.defaultPromoCode.code;

  useEffect(() => {
    if (fetchedCode && fetchedCode !== storedDiscountCode) {
      setItem(DISCOUNT_CODE_KEY, fetchedCode);
      setExpired();
    }
  }, [storedDiscountCode, fetchedCode]);

  useEffect(() => {
    if (discountText && discountText !== storedDiscountText) {
      setItem(DISCOUNT_TEXT_KEY, discountText);
      setExpired();
    }
  }, [storedDiscountText, discountText]);

  return {
    code: fetchedCode,
    text: discountText,
    discountCampaign: data?.promoCode ?? data?.defaultPromoCode,
  };
}
