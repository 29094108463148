"use client";
import { useEffect } from "react";
import { usePathname } from "next/navigation";
import { hotjar } from "react-hotjar";

// <!-- Hotjar Tracking Code for Craft Gin Club -->
// <script>
//     (function(h,o,t,j,a,r){
//     h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
//     h._hjSettings={hjid:3563221,hjsv:6};
//     a=o.getElementsByTagName(‘head’)[0];
//     r=o.createElement(‘script’);r.async=1;
//     r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
//     a.appendChild(r);
// })(window,document,‘https://static.hotjar.com/c/hotjar-','.js?sv=’);
// </script>

const ALLOWED_PAGES = ["/", "/membership-purchase-confirmation-shpfy"];

const Hotjar = () => {
  const pathname = usePathname()!;
  useEffect(() => {
    if (ALLOWED_PAGES.includes(pathname)) {
      hotjar.initialize(3563221, 6);
    }
  }, [pathname]);

  return null;
};

export default Hotjar;
