export const SITE_NAME = "Craft Gin Club | The UK's No.1 gin club";

export const SITE_LOCALE = "en_GB";

export const CURRENT_LOCALE = SITE_LOCALE.split("_")[1];

export const GOOGLE_VERIFICATION_IDS = [
  "xXSKLZBaPw5wvSdjxuxUJf2Z4h-uHRBqTY1p4zuVtXY",
  "T0PTq_3hZJq3tR7jJuMOPx31gU9ochrFRmLvbNx-aC4",
];

// export const GLOBAL_EU_SITE = "https://global-eu.craftginclub.co.uk/";
export const GLOBAL_EU_SITE = "https://hello.craftginclub.co.uk/global-eu/";

export const GTM_ID = "GTM-PMB23ZQ";
