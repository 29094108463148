"use client";

import { MARoutes } from "@/app/(memberzone)/routes";
import { components } from "@/app/(memberzone)/slices";
import {
  GIN_CLASSIC_VARIANT_ID,
  GIN_SUBSCRIPTION_PRODUCT_IDS,
  GIN_TASTER_VARIANT_ID,
  RUM_CLASSIC_VARIANT_ID,
  RUM_SUBSCRIPTION_PRODUCT_ID,
  THIRST_CLASS_PRODUCT_ID,
  THIRST_CLASS_VARIANT_ID,
} from "@/app/data/constants";
import PureCarousel from "@/components/ui/PureCarousel";
import { MembersAreaHomePageDocument } from "@/prismicio-types";
import { Customer, Subscription } from "@/types/subscriptions";
import clsx from "@/utils/clsx";
import { DeliveryCarIcon, GinBottlesIcon, GinBoxIcon } from "@cgc/ui";
import { FilledContentRelationshipField } from "@prismicio/client";
import { SliceZone } from "@prismicio/react";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

type Cohort =
  | "all"
  | "is-in-payment-failure"
  | "has-rum-classic-plan"
  | "has-had-rum-plan"
  | "has-thirst-class"
  | "has-gin-classic-plan"
  | "has-gin-taster-plan"
  | "has-no-active-plan"
  | "has-mention-me-discount";

function determineCohorts(
  customer: Customer,
  subscriptions: Subscription[],
  hadRumSubscription: boolean,
  hasMentionMeDiscount: Boolean,
): Cohort[] {
  const cohorts: Cohort[] = ["all"];

  if (
    customer.has_payment_method_in_dunning ||
    !customer.has_valid_payment_method
  ) {
    cohorts.push("is-in-payment-failure");
  }

  if (subscriptions.length === 0 || customer.subscriptions_active_count === 0) {
    cohorts.push("has-no-active-plan");
  }

  if (
    subscriptions.some(
      (sub) =>
        Number(sub?.external_variant_id?.ecommerce) ===
          THIRST_CLASS_VARIANT_ID ||
        Number(sub?.external_product_id?.ecommerce) === THIRST_CLASS_PRODUCT_ID,
    )
  ) {
    cohorts.push("has-thirst-class");
  }

  const hasHadRum =
    subscriptions.some(
      (sub) => Number(sub.external_product_id) === RUM_SUBSCRIPTION_PRODUCT_ID,
    ) || hadRumSubscription;

  if (hasHadRum) cohorts.push("has-had-rum-plan");

  if (hasMentionMeDiscount) cohorts.push("has-mention-me-discount");

  subscriptions.map((sub) => {
    if (
      GIN_SUBSCRIPTION_PRODUCT_IDS.includes(+sub.external_product_id.ecommerce)
    ) {
      if (
        sub.external_variant_id.ecommerce === GIN_CLASSIC_VARIANT_ID.toString()
      )
        cohorts.push("has-gin-classic-plan");
      if (
        sub.external_variant_id.ecommerce === GIN_TASTER_VARIANT_ID.toString()
      )
        cohorts.push("has-gin-taster-plan");
    }
    if (
      sub.external_product_id.ecommerce ===
      RUM_SUBSCRIPTION_PRODUCT_ID.toString()
    ) {
      if (
        sub.external_variant_id.ecommerce === RUM_CLASSIC_VARIANT_ID.toString()
      )
        cohorts.push("has-rum-classic-plan");
    }
  });

  return cohorts;
}

const Benefit = ({
  label,
  Icon,
}: {
  label: string;
  Icon: (props: any) => JSX.Element;
}) => {
  return (
    <div key={label} className="flex h-12 items-center justify-center gap-2">
      <Icon className="h-[25px] lg:h-[25px]" />{" "}
      <p className="!text-[12px] font-semibold leading-none sm:text-base">
        {label}
      </p>
    </div>
  );
};

const visiblePaths = [
  MARoutes.SUBSCRIPTIONS_LIST,
  MARoutes.ACCOUNT_DETAILS,
  MARoutes.ORDERS_LIST,
  MARoutes.REFER_A_FRIEND,
  MARoutes.REWARDS,
];

export const MembersAreaPromoBanner = ({
  subscriptions,
  customer,
  settings,
  hadRumSubscription,
  hasMentionMeDiscount,
}: {
  subscriptions: Subscription[];
  hadRumSubscription: boolean;
  customer: Customer;
  settings: MembersAreaHomePageDocument;
  hasMentionMeDiscount?: Boolean;
}) => {
  const path = usePathname();

  const [isVisible, setIsVisible] = useState(visiblePaths.includes(path));

  useEffect(() => {
    setIsVisible(visiblePaths.includes(path));
  }, [path]);

  const benefits = [
    { label: "Total flexibility, no commitment", icon: GinBoxIcon },
    { label: "A world of unique, crafted spirits", icon: GinBottlesIcon },
    { label: "Easy, free and reliable delivery", icon: DeliveryCarIcon },
  ];

  const cohorts = determineCohorts(
    customer,
    subscriptions,
    hadRumSubscription,
    hasMentionMeDiscount,
  );

  const filteredBanners = [];

  for (const slice of settings.data.slices) {
    if (
      slice.primary.enabled &&
      cohorts.includes(
        (slice.primary.cohort as FilledContentRelationshipField).slug as Cohort,
      )
    ) {
      // Filter exclusions
      if (slice.primary.exclusions.length) {
        const excludeCohorts = slice.primary.exclusions.map(
          (c) => c.cohort.slug,
        );

        if (
          excludeCohorts.some((cohort) => cohorts.includes(cohort as Cohort))
        ) {
          continue;
        }
      }

      filteredBanners.push(slice);
    }
  }

  return (
    <div
      className={clsx({
        "h-auto": isVisible,
        "h-0 overflow-hidden lg:h-auto": !isVisible,
      })}
    >
      <PureCarousel
        autoplay
        autoplayInterval={15000}
        wrapAround
        className="mb-0 pb-0 lg:max-w-[calc(100vw-300px)]"
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
      >
        {filteredBanners.map((slice) => (
          <SliceZone key={slice.id} slices={[slice]} components={components} />
        ))}
      </PureCarousel>

      <div className="text-cgc-blue hidden h-12 flex-row items-center justify-center overflow-hidden bg-white px-4 py-2 lg:flex lg:gap-x-9">
        {benefits.map(({ label, icon: Icon }) => (
          <Benefit key={label} label={label} Icon={Icon} />
        ))}
      </div>

      <PureCarousel
        autoplay
        withoutControls
        wrapAround
        className="text-cgc-blue mb-0 bg-white pb-0 lg:hidden"
      >
        {benefits.map(({ label, icon: Icon }) => (
          <Benefit key={label} label={label} Icon={Icon} />
        ))}
      </PureCarousel>
    </div>
  );
};
