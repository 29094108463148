"use client";
import React from "react";
import Script from "next/script";

declare global {
  interface Window {
    ometria: any;
  }
}

function OmetriaSnippet() {
  return <Script src="https://cdn.ometria.com/tags/9487896936c7a02e.js" />;
}

export default OmetriaSnippet;
