"use client";
import React from "react";
import Image from "next/image";

type SizeProps = {
  size?: number;
  mobSize?: number;
};

type SocialIconProps = {
  alt: string;
  src: string;
} & SizeProps;

const DEFAULT_SIZE = 36;
const DEFAULT_SIZE_MOBILE = 25;

export const SocialIcon: React.FC<SocialIconProps> = ({
  src,
  alt,
  size = DEFAULT_SIZE,
  mobSize = DEFAULT_SIZE_MOBILE,
}) => {
  return (
    <>
      <Image
        src={src}
        alt={alt}
        width={mobSize}
        height={mobSize}
        className="block h-auto md:hidden"
      />
      <Image
        src={src}
        alt={alt}
        width={size}
        height={size}
        className="hidden h-auto md:block"
      />
    </>
  );
};

export const TiktokIcon: React.FC<SizeProps> = (props) => (
  <SocialIcon {...props} src="/tiktok-icon.svg" alt="TikTok" />
);

export const FacebookIcon: React.FC<SizeProps> = (props) => (
  <SocialIcon {...props} src="/fb-icon.svg" alt="Facebook" />
);

export const InstagramIcon: React.FC<SizeProps> = (props) => (
  <SocialIcon {...props} src="/instagram-icon.svg" alt="Instagram" />
);

export const PinterestIcon: React.FC<SizeProps> = (props) => (
  <SocialIcon {...props} src="/pinterest-icon.svg" alt="Pinterest" />
);

export const TwitterIcon: React.FC<SizeProps> = (props) => (
  <SocialIcon {...props} src="/twitter-icon.svg" alt="Twitter" />
);

export const YoutubeIcon: React.FC<SizeProps> = (props) => (
  <SocialIcon {...props} src="/youtube-icon.svg" alt="Youtube" />
);

export const GinnedBlogIcon: React.FC<SizeProps> = (props) => (
  <SocialIcon {...props} src="/blog-icon.svg" alt="Ginned Blog" />
);

export const LinkedInIcon: React.FC<SizeProps> = (props) => (
  <SocialIcon {...props} src="/linkedin-icon.svg" alt="Linkedin" />
);

export const WhatsAppIcon: React.FC<SizeProps> = (props) => (
  <SocialIcon {...props} src="/whatsapp-icon.svg" alt="WhatsApp" />
);
