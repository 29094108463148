import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "@heroicons/react/20/solid";
import type { CarouselProps, ControlProps } from "nuka-carousel";
import Carousel from "nuka-carousel";
import type { JSX } from "react";
import { useCallback } from "react";
import { clsx } from "../utils/clsx";
import { Button } from "./button";

interface ClassNames {
  nextClassName?: string;
  prevClassName?: string;
  dotClassName?: string;
  dotsContainerClassName?: string;
}

export interface PureCarouselProps extends CarouselProps {
  classNames?: ClassNames;
  controlSize?: "small" | "default";
  indicators?: boolean;
  variant?: "default" | "topRightControls";
}

export function PureCarousel({
  controlSize = "default",
  className,
  classNames,
  indicators = true,
  variant = "default",
  ...props
}: PureCarouselProps): JSX.Element {
  const isTopRightControls = variant === "topRightControls";
  const isSmallControls = controlSize === "small" || isTopRightControls;

  const renderNext = useCallback(
    ({
      nextSlide,
      nextDisabled,
    }: ControlProps) => {
      if (nextDisabled) return null;

      return <Button
        aria-label="Next slide"
        className={clsx(
          "p-0",
          isTopRightControls && "absolute -top-11 right-0",
          classNames?.nextClassName,
        )}
        color="unstyled"
        onClick={nextSlide}
      >
        <ArrowRightCircleIcon className={clsx({
          "h-8 w-8": isSmallControls,
          "h-8 w-8 md:h-12 md:w-12": !isSmallControls,
        })} />
      </Button>;
    },
    [isTopRightControls, classNames?.nextClassName, isSmallControls],
  );

  const renderPrevious = useCallback(
    ({
      previousSlide,
      previousDisabled,
    }: ControlProps) => {
      if (previousDisabled) return null;

      return <Button
        aria-label="Previous slide"
        className={clsx(
          "p-0",
          isTopRightControls && "absolute -top-11 right-8",
          classNames?.prevClassName,
        )}
        color="unstyled"
        onClick={previousSlide}
      >
        <ArrowLeftCircleIcon className={clsx({
          "h-8 w-8": isSmallControls,
          "h-8 w-8 md:h-12 md:w-12": !isSmallControls,
        })} />
      </Button>;
    },
    [classNames?.prevClassName, isTopRightControls, isSmallControls],
  );

  return (
    <Carousel
      className={clsx("mb-5 pb-7", className)}
      renderBottomCenterControls={({
        pagingDotsIndices,
        currentSlide,
        goToSlide,
      }: ControlProps) => {
        if (!indicators) return null;

        return <div className="flex flex-row gap-2 p-2">
          {pagingDotsIndices.map((index) => (
            <button
              aria-label={`Indicator of slide ${index + 1}`}
              className={clsx(
                "size-1.5 rounded-full p-1.5",
                {
                  "bg-cgc-blue border-cgc-blue-10 border": currentSlide === index,
                  "bg-cgc-blue-10 border-cgc-blue-10 border": currentSlide !== index,
                },
                classNames?.dotClassName,
              )}
              color="unstyled"
              key={`indicator:${index}`}
              onClick={() => {
                goToSlide(index);
              }}
            />
          ))}
        </div>;
      }}
      renderCenterLeftControls={variant === "default" ? renderPrevious : null}
      renderCenterRightControls={variant === "default" ? renderNext : null}
      renderTopLeftControls={variant === "topRightControls" ? renderPrevious : null}
      renderTopRightControls={variant === "topRightControls" ? renderNext : null}

      {...props}
    />
  );
}
