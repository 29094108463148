import Url, { UrlObject } from "url";
import { DISCOUNT_CODE_KEY } from "@/data/helpers/promoCode/hooks";

const SIGNUP_LINK = "/pages/sign-up";

export type UrlType = string | UrlObject;

function getBaseUrl() {
  return typeof window === "undefined" ? process.env.HOST : location.origin;
}

export function isSignUpLink(url: UrlType) {
  const pathname =
    typeof url === "string"
      ? new URL(url, getBaseUrl()).pathname
      : url.pathname;

  return pathname === SIGNUP_LINK;
}

export function addDiscountCode(url: UrlType, code: string) {
  const url_ = new URL(
    typeof url === "string" ? url : Url.format(url),
    getBaseUrl(),
  );
  url_.searchParams.set(DISCOUNT_CODE_KEY, code);

  return url_.href;
}
