"use client";

import Image from "next/image";
import { useEffect, useState } from "react";
import { CURRENT_LOCALE, GLOBAL_EU_SITE } from "@/app/config";
import { Button, Container, Heading, Paragraph } from "@cgc/ui";
import { useBottomBanner } from "@cgc/ui/bottom-banner";
import { sendGTMEvent } from "@next/third-parties/google";
import { usePathname } from "next/navigation";

const ALLOWED_COUNTRIES = [
  "AT", // Austria
  "BE", // Belgium
  "BG", // Bulgaria
  "HR", // Croatia
  "CY", // Cyprus
  "CZ", // Czech Republic
  "DK", // Denmark
  "EE", // Estonia
  "FI", // Finland
  "FR", // France
  "DE", // Germany
  "GR", // Greece
  "HU", // Hungary
  "IE", // Ireland
  "IT", // Italy
  "LV", // Latvia
  "LT", // Lithuania
  "LU", // Luxembourg
  "MT", // Malta
  "NL", // Netherlands
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SK", // Slovakia
  "SI", // Slovenia
  "ES", // Spain
  "SE", // Sweden
];

const STORE_KEY = "_geo_country";
const STORE_REDIRECT_KEY = "_geo_auto_redirected";

function skip() {
  sessionStorage.setItem(STORE_KEY, "skipped");
}

function fetched(code: string) {
  sessionStorage.setItem(STORE_KEY, code || "fetched");
}

const GeoBanner = () => {
  const pathname = usePathname();
  const [showBanner, setShowBanner] = useState(false);
  const { BottomBanner } = useBottomBanner(true);
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const hasAutoRedirected = sessionStorage.getItem(STORE_REDIRECT_KEY);

  useEffect(() => {
    const code = sessionStorage.getItem(STORE_KEY);

    setCountryCode(code);
  }, []);

  useEffect(() => {
    if (countryCode) {
      return;
    }

    fetch("https://europe-west2-cgc-shopify.cloudfunctions.net/ge-location-2")
      .then((response) => response.json())
      .then((data) => {
        fetched(data.country);
        setCountryCode(data.country);
      });
  }, [countryCode]);

  useEffect(() => {
    if (countryCode === "skipped") {
      return;
    }

    if (
      countryCode !== CURRENT_LOCALE &&
      ALLOWED_COUNTRIES.includes(countryCode)
    ) {
      if (!hasAutoRedirected && !pathname.includes("ginnedmagazine")) {
        // Automatically navigate to the EU site
        sessionStorage.setItem(STORE_REDIRECT_KEY, "true");
        window.location.href = GLOBAL_EU_SITE;
      } else {
        setShowBanner(true);
      }
    }
  }, [countryCode, pathname, hasAutoRedirected]);

  useEffect(() => {
    if (!showBanner) {
      return;
    }

    sendGTMEvent({
      event: "site_interaction",
      context: "geo_banner",
      action: "displayed",
    });
  }, [showBanner]);

  if (!showBanner) {
    return null;
  }

  return (
    <BottomBanner
      verticalAlignment="middle"
      onClose={() => {
        skip();

        sendGTMEvent({
          event: "site_interaction",
          context: "geo_banner",
          action: "closed",
        });
      }}
    >
      <Container className="space-y-2 text-center md:!py-0">
        <Heading.h3 className="text-white">
          We now deliver across Europe!
        </Heading.h3>
        <Paragraph className="text-white">
          Get your exclusive welcome offer at our new Europe site today.
        </Paragraph>
        <div>
          <a
            href={GLOBAL_EU_SITE}
            onClick={() => {
              sendGTMEvent({
                event: "site_interaction",
                context: "geo_banner",
                action: "navigated",
              });
            }}
          >
            <Button
              color="secondary"
              typoComponent={() => (
                <Paragraph className="text-cgc-blue flex flex-row items-center gap-2 px-3 normal-case">
                  <Image
                    src="/eu-flag.svg"
                    width={27}
                    height={27}
                    alt="European Union Flag"
                  />
                  Go to the Europe site
                </Paragraph>
              )}
            />
          </a>
        </div>
      </Container>
    </BottomBanner>
  );
};

export default GeoBanner;
