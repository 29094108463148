"use client";
import Script from "next/script";
import { useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";

declare global {
  interface Window {
    pintrk: any;
  }
}

export default function PinterestSnippet() {
  const [ready, setReady] = useState(false);
  const email = useSearchParams()?.get("email");

  useEffect(() => {
    if (!window.pintrk) {
      const pintrk = function (...args: any) {
        window.pintrk.queue.push(Array.prototype.slice.call(args));
      };

      pintrk.queue = [] as any[];
      pintrk.version = "3.0";
      window.pintrk = pintrk;
      setReady(true);
    }
  }, []);

  return ready ? (
    <Script
      src="https://s.pinimg.com/ct/core.js"
      onLoad={() => {
        window.pintrk("load", "2616196285968", { em: email });
        window.pintrk("page");
      }}
    />
  ) : null;
}
